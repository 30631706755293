<template>
    <div>
		
		<el-row :gutter="20" class="mgb20">
		        <el-col :span="4">
		            <el-card shadow="hover" :body-style="{ padding: '0px' }">
		                <div class="grid-content grid-con-1">
		                    <i class="el-icon-lx-recharge grid-con-icon"></i>
		                    <div class="grid-cont-right">
		                        <div class="grid-num">{{profit.withdraw_rest}}</div>
		                        <div>可提现(元)</div>
		                    </div>
		                </div>
		            </el-card>
		        </el-col>
		        <el-col :span="4">
		            <el-card shadow="hover" :body-style="{ padding: '0px' }">
		                <div class="grid-content grid-con-2">
		                    <i class="el-icon-lx-recharge grid-con-icon"></i>
		                    <div class="grid-cont-right">
		                        <div class="grid-num">{{profit.withdrawing}}</div>
		                        <div>提现中(元)</div>
		                    </div>
		                </div>
		            </el-card>
		        </el-col>
		        <el-col :span="4">
		            <el-card shadow="hover" :body-style="{ padding: '0px' }">
		                <div class="grid-content grid-con-3">
		                    <i class="el-icon-lx-recharge grid-con-icon"></i>
		                    <div class="grid-cont-right">
		                        <div class="grid-num">{{profit.withdrawn}}</div>
		                        <div>已提现(元)</div>
		                    </div>
		                </div>
		            </el-card>
		        </el-col>
		    </el-row>
		
       	<div class="crumbs">
			   <el-breadcrumb separator="/">
				   <el-breadcrumb-item>
					   <i class="el-icon-lx-cascades"></i> 提现记录
				   </el-breadcrumb-item>
			   </el-breadcrumb>
		</div>
		<div class="container">
		    <div class="handle-box">
		      <!--  <el-button
		            type="primary"
		            icon="el-icon-delete"
		            class="handle-del mr10"
		            @click="delAllSelection"
		        >批量删除</el-button> -->
				<div style="width: 100px;text-align: center;line-height: 40px; font-size: 14px; color:#666666; ">提现状态：</div>
		       <el-select v-model="query.state" placeholder="提现状态" class="handle-select mr10">
		            <el-option v-for="(item,index) in type_state" :key="index" :label="item.name" :value="item.lable"></el-option>
					  
					<!-- <el-option key="1" label="广东省" value="0"></el-option>
		            <el-option key="2" label="湖南省" value="1"></el-option> -->
		        </el-select>
				<el-button type="primary"  @click="search()">搜索</el-button>
				<el-button type="primary"  @click="launch_withdraw()">申请提现</el-button>
		    </div>
		    <el-table
		        :data="tableData"
		        border
		        class="table"
		        ref="multipleTable"
		        header-cell-class-name="table-header"
		        @selection-change="handleSelectionChange"
		    >
<!-- 		        <el-table-column type="selection" width="55" align="center"></el-table-column>
 -->		  <el-table-column prop="id" label="ID"></el-table-column>
				<!-- <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column> -->
		     <el-table-column prop="wallet.wallet_type" label="平台"></el-table-column>
			 <el-table-column prop="wallet.account_number" label="账号"></el-table-column>
			 <el-table-column prop="money" label="金额"></el-table-column>
				
		       <el-table-column label="提现状态" align="center">
		            <template #default="scope">
						<el-tag v-if="scope.row.state ===0" type="danger" >待审核</el-tag>
						<el-tag v-if="scope.row.state ===1" type="success" >打款中</el-tag>
						<el-tag v-if="scope.row.state ===2" type="success" >已打款</el-tag>
						<el-tag v-if="scope.row.state ===3" type="danger" >已拒绝</el-tag>
		                <!-- <el-tag
		                    :type="
		                        scope.row.state === 1 
		                            ? 'success'
		                            : scope.row.state === 0
		                            ? 'danger'
		                            : ''
		                    "
		                >{{ scope.row.state }}</el-tag> -->
		            </template>
		        </el-table-column>
				<el-table-column prop="datetime" label="提现时间"></el-table-column>
				<el-table-column prop="reject" label="拒绝原因"></el-table-column>
				

		    </el-table>
		    <div class="pagination">
		        <el-pagination
		            background
		            layout="total, prev, pager, next"
		            :current-page="query.p"
		            :page-size="query.num_of_page"
		            :total="pageTotal"
		            @current-change="handlePageChange"
		        ></el-pagination>
		    </div>
		</div>
		<el-dialog title="提现申请" v-model="editVisible" width="30%">
		    <el-form ref="form" :model="with_form" label-width="70px">
		        <el-form-item label="提现金额">
		            <el-input v-model="with_form.money" ></el-input>
		        </el-form-item>
		       <!-- <el-form-item label="地址">
		            <el-input v-model="form.address"></el-input>
		        </el-form-item> -->
		    </el-form>
			
		    <template #footer>
		        <span class="dialog-footer">
		            <el-button @click="editVisible = false">取 消</el-button>
		            <el-button type="primary" @click="save_launch_withdraw">确 定</el-button>
		        </span>
		    </template>
		</el-dialog>
		
		<el-dialog title="填写收款方式" v-model="zf_editVisible" width="30%">
		    <el-form ref="form" :model="form" label-width="100px">
		        <el-form-item label="真实姓名">
		            <el-input v-model="form.name"></el-input>
		        </el-form-item>
		        <el-form-item label="支付宝账号">
		            <el-input v-model="form.phone"></el-input>
		        </el-form-item>
		    </el-form>
		    <template #footer>
		        <span class="dialog-footer">
		            <el-button @click="zf_editVisible = false">取 消</el-button>
		            <el-button type="primary" @click="add_Withdraw">确 定</el-button>
		        </span>
		    </template>
		</el-dialog>
		
		
	</div>
</template>

<script>
import {GetWallet,Withdraw,LaunchWithdraw,AddWithdraw} from "../api/index";
	
export default {
    name: "withdraw",
	data() {
	    return {
	        query: {
	            num_of_page:40,
				p:1,
				state:0,
	        },
			with_form:{
				money:''	
			},
			profit:{
			withdraw_rest:0,//可提现
			withdrawing:0,//提现中
			withdrawn:0,//以提现
			},
			wallet:{},
			
			type_state:[
				{
					lable:0,
					name:"全部",
					value:0
				},
				{
					lable:1,
					name:"待审核",
					value:1
				},
				{
					lable:2,
					name:"待打款",
					value:2
				},
				{
					lable:3,
					name:"已打款",
					value:3
				},
				{
					lable:4,
					name:"已拒绝",
					value:4
				}
			],
			
	        tableData: [],
	        multipleSelection: [],
	        delList: [],
	        editVisible: false,
			zf_editVisible:false,
	        pageTotal: 0,
	        form: {
				name:'',
				phone:''
			},
	        idx: -1,
	        id: -1,
			is_wallet:0
			
	    };
	},
	created() {
	        this.getData();
			this.get_withdraw()
	    },
	    methods: {
				
	        getData() {
				// console.log("111111")
				// this.query.promote_id = this.$route.query.promote_id
	            GetWallet().then(res => {
	                console.log(res.data);
					this.profit =res.data.profit
					if(res.data.wallet.user){
						this.is_wallet=1
					}else{
						this.is_wallet=0
					}
	                // this.tableData = res.data.results;
	                // this.pageTotal = res.data.total || 50;
	            });
	        },
			get_withdraw(){
				Withdraw(this.query).then(res => {
				    this.tableData = res.data.results;
				    this.pageTotal = res.data.total;
				});
			},
			//发起提现
			launch_withdraw(){
				if(this.is_wallet ==1){
					this.editVisible=true
				}else{
					this.zf_editVisible=true
				}
			},
			//发起提现
			save_launch_withdraw(){
			LaunchWithdraw(this.with_form).then(res => {
			     console.log(res)
				 if(res.code==0){
					 this.$message(res.data);
				 }else{
					 this.$message(res.msg);
				 }
				 this.editVisible=false
				 
			});	
			},
			//增加收款方式
			add_Withdraw(){
				AddWithdraw(this.form).then(res=>{
					if(res.code==0){
								this.is_wallet =1
										 this.$message(res.data);
					}else{
										 this.$message(res.msg);
					}
					this.zf_editVisible=false
				})
			},
			//搜索
			search(){
				console.log(this.query)
				this.get_withdraw()
			},
	        // 触发搜索按钮
	        handleSearch() {
	            this.$set(this.query, "pageIndex", 1);
	            this.getData();
	        },
	        // 删除操作
	        handleDelete(index) {
	            // 二次确认删除
	            this.$confirm("确定要删除吗？", "提示", {
	                type: "warning"
	            })
	                .then(() => {
	                    this.$message.success("删除成功");
	                    this.tableData.splice(index, 1);
	                })
	                .catch(() => {});
	        },
	        // 多选操作
	        handleSelectionChange(val) {
	            this.multipleSelection = val;
	        },
	        delAllSelection() {
	            const length = this.multipleSelection.length;
	            let str = "";
	            this.delList = this.delList.concat(this.multipleSelection);
	            for (let i = 0; i < length; i++) {
	                str += this.multipleSelection[i].name + " ";
	            }
	            this.$message.error(`删除了${str}`);
	            this.multipleSelection = [];
	        },
	        // 编辑操作
	        handleEdit(row) {
	            // this.idx = index;
	            // this.form = row;
	            // this.editVisible = true;
				 this.$router.push({
				'path':'/singlesub',
				'name':'singlesub',
				query:{promote_id:row.promote_id}
				  
				});

	        },
	        // 保存编辑
	        saveEdit() {
	            this.editVisible = false;
	            this.$set(this.tableData, this.idx, this.form);
	        },
	        // 分页导航
	        handlePageChange(val) {
	            this.query.p=val
	            this.getData();
	        }
	    }
	
};
</script>

<style scoped>
.el-row {
    margin-bottom: 20px;
}

.grid-content {
    display: flex;
    align-items: center;
    height: 80px;
}

.grid-cont-right {
    flex: 1;
    text-align: center;
    font-size: 10px;
    color: #999;
}

.grid-num {
    font-size: 20px;
    font-weight: bold;
}

.grid-con-icon {
    font-size: 24px;
    width: 60px;
    height: 80px;
    text-align: center;
    line-height: 80px;
    color: #fff;
}

.grid-con-1 .grid-con-icon {
    background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
    background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
    background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
    color: rgb(242, 94, 67);
}
.handle-box {
    margin-bottom: 20px;
	display: flex;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 200px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
</style>